@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.errorAlert {
  position: absolute;
  top: 3%;
  right: 0;
}
.line-chart {
  color: #fff;
}
.image-class {
  height: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* width: 200px; */
}
.image {
  max-width: 100%;
  max-height: 100%;
}
.selected-image {
  border: 4px solid #0369a1;
  border-radius: 20px;
}
.tooltip {
  position: relative;
  z-index: 99999;
}

/* Tooltip text */
.tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  bottom: 130%;
  left: -60%;
  position: absolute;
  z-index: 99999;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 11px;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  z-index: 99999;
  border-color: black transparent transparent transparent;
}
#inputID::placeholder {
  opacity: 0.6;
}
